import React from 'react';
import sanitizeHtml from 'sanitize-html';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { getPhoneNumber } from 'util/globalVariables';

import './index.style.scss';
import { AutoRefillTermsAndConditionsModalProps } from './types';

export const AutoRefillTermsAndConditionsModal: React.FC<AutoRefillTermsAndConditionsModalProps> = ({ t }) => {
    return (
        <BirdiModalContent
            title={t('modals.autoRefillTC.title')}
            eyebrowText={t('modals.autoRefillTC.eyebrow')}
            body={
                <div className="auto-refill-tc-body">
                    <span className="text-dark auto-refill-tc-terms-label">{t('modals.autoRefillTC.termsLabel')}</span>
                    <ol>
                        <li>
                            <span className="text-dark">{t('modals.autoRefillTC.termsOne')}</span>
                        </li>
                        <li>
                            <span className="text-dark">{t('modals.autoRefillTC.termsTwo')}</span>
                        </li>
                        <li>
                            <span className="text-dark">{t('modals.autoRefillTC.termsThree')}</span>
                        </li>
                        <li>
                            <span className="text-dark">{t('modals.autoRefillTC.termsFour')}</span>
                        </li>
                        <li>
                            <span className="text-dark">{t('modals.autoRefillTC.termsFive')}</span>
                        </li>
                        <li>
                            <span
                                className="text-dark"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(
                                        t('modals.autoRefillTC.termsSix') +
                                            getPhoneNumber({ type: 'birdi', isEnd: true })
                                    )
                                }}
                            />
                        </li>
                    </ol>
                </div>
            }
            icon={'none'}
        />
    );
};
