import React, { ReactElement } from 'react';
import classNames from 'classnames';
import InfoIcon from 'ui-kit/icons/info/circle-info-icon';

import './info-box.style.scss';

interface InfoBoxProps {
    icon?: ReactElement | JSX.Element;
    button?: ReactElement | JSX.Element;
    buttonPosition?: 'inline' | 'right';
    children: React.ReactNode | React.ReactNode[];
}
interface InfoBoxWrapperProps {
    buttonPosition: 'inline' | 'right';
    children: React.ReactNode | React.ReactNode[];
}

const InfoBoxContent = ({ buttonPosition, children }: InfoBoxWrapperProps) => {
    return buttonPosition === 'right' ? <>{children}</> : <div className="info-box__content">{children}</div>;
};

const InfoBox = ({
    icon = <InfoIcon className="info-icon__rotated" />,
    buttonPosition = 'inline',
    children,
    button
}: InfoBoxProps): ReactElement => {
    return (
        <div className={classNames('info-box', `info-box--${buttonPosition}`)}>
            <div className="info-box__icon">{icon}</div>
            <InfoBoxContent buttonPosition={buttonPosition}>
                <div className="info-box__text">{children}</div>
                {button && <div className="info-box__button">{button}</div>}
            </InfoBoxContent>
        </div>
    );
};

export default InfoBox;
